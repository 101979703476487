<template>
  <div class="about">
    <img class="banner" src="@/assets/img/about/about.png" />
    <!-- 企业简介 -->
    <brief></brief>
    <!-- 我们的管理团队 -->
    <team></team>
    <!-- 与我们一起创造不凡 -->
    <cooperation></cooperation>
  </div>
</template>

<script>
import brief from './brief.vue'
import team from './team.vue'
import cooperation from './cooperation.vue'
export default {
  name: 'About',
  components: {
    brief,
    team,
    cooperation,
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      let res = await this.$axios.post('/api/Contact/aboutus')
      if (res.code === 1) {
        this.bankList = res.data
      }
    },
  },
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
}
</style>
