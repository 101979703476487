<template>
  <div class="brief mainBox flex-start">
    <div class="brief-cont">
      <div class="tit">
        {{ $t('index_brief_1') }}
      </div>
      <div class="tit-tip">company profiles</div>
      <div class="brief-text">
        {{ $t('company_profiles_content') }} 
      </div>
    </div>
    <div class="brief-img">
      <img class="banner" src="@/assets/img/about/brief.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carHeight: '480px',
    }
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 480 + 'px'
    },
  },
}
</script>

<style scoped lang="scss">
.brief {
  min-height: 400px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 60px;
}
.brief-cont {
  width: 490px;
  margin: 40px 30px 0 40px;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .tit-tip {
    font-size: 16px;
    color: #999999;
    margin-top: 6px;
  }
  .brief-text {
    font-size: 12px;
    color: #666666;
    margin-top: 24px;
    line-height: 20px;
  }
}
.brief-img {
  img {
    width: 600px;
    position: relative;
    top: -30px;
  }
}
</style>
