<template>
  <div class="team mainBox">
    <div class="team-title">{{ $t('index_team_1') }}</div>
    <div class="brief-cont-box flex-start">
      <div class="logo">
        <img v-if="memberIndex==0" :src="memberList[0].pic"/>
        <img v-if="memberIndex==1" :src="memberList[1].pic"/>
        <img v-if="memberIndex==2" :src="memberList[2].pic"/>
      </div>
      <div class="brief-cont">
        <div class="tit"> {{memberList[memberIndex].name}}</div>
        <div class="tit-tip"> {{memberList[memberIndex].title}}</div>
        <div class="brief-text">
          {{memberList[memberIndex].desc}}
        </div>
        <div class="brief-list">
          <el-image v-if="memberIndex!=0" fit="scale-down" class="list-img" @click="setCurrentMember(0)" :src="memberList[0].pic"/>
          <el-image v-if="memberIndex!=1" fit="scale-down" class="list-img" @click="setCurrentMember(1)" :src="memberList[1].pic"/>
          <el-image v-if="memberIndex!=2" fit="scale-down" class="list-img" @click="setCurrentMember(2)" :src="memberList[2].pic"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carHeight: '480px',
      memberList: [
        {
          name: 'Alvin Leong',
          title: this.$t('index_team_2'),
          desc: this.$t('index_team_content'),
          pic: require('@/assets/img/about/m1.jpg')
        },
        {
          name: 'Darren Tan',
          title: this.$t('index_team2_2'),
          desc: this.$t('index_team2_content'),
          pic: require('@/assets/img/about/m2.jpg')
        },
        {
          name: 'Peter',
          title: this.$t('index_team3_2'),
          desc: this.$t('index_team3_content'),
          pic: require('@/assets/img/about/m3.jpg')
        }
      ],
      memberIndex: 0
    }
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 480 + 'px'
    },
    setCurrentMember(index) {
      this.memberIndex = index
    }
  },
}
</script>

<style scoped lang="scss">
.team {
  margin-top: 40px;
}

.team-title {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
  text-align: center;
}

.brief-cont-box {
  .logo {
    margin-right: 40px;

    img {
      width: 300px;
    }
  }
}

.brief-cont {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }

  .tit-tip {
    font-size: 16px;
    color: #999999;
    margin-top: 6px;
  }

  .brief-text {
    font-size: 12px;
    color: #666666;
    margin-top: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .brief-list {
    font-size: 12px;
    color: #666666;
    margin-top: 24px;
    line-height: 20px;

    .list-img {
      height: 160px;
      border-radius: 16px;
      margin-right: 20px;
    }
  }
}
</style>
