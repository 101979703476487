<template>
  <div class="cooperation mainBox">
    <div class="cooperation-title">{{ $t('index_cooperation_1') }}</div>
    <div class="cooperation-cont flex-start">
      <div class="cooperation-type">
        <div class="cooperation-item top">
          <div class="tit">{{ $t('index_cooperation_2') }}</div>
          <img class="list-img" src="@/assets/img/about/1.png" />
        </div>
        <div class="cooperation-item">
          <div class="tit">{{ $t('index_cooperation_3') }}</div>
          <img class="list-img" src="@/assets/img/about/2.png" />
        </div>
      </div>
      <div class="cooperation-center">
        <img class="img" src="@/assets/img/about/cooperation.png" />
      </div>
      <div class="cooperation-type right">
        <div class="cooperation-item top">
          <div class="tit">{{ $t('index_cooperation_4') }}</div>
          <img class="list-img" src="@/assets/img/about/3.png" />
        </div>
        <div class="cooperation-item">
          <div class="tit">USD+$5BN+</div>
          <img class="list-img" src="@/assets/img/about/4.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carHeight: '480px',
    }
  },
  methods: {
    getCarHeight() {
      const clientWidth = document.documentElement.clientWidth
      this.carHeight = (clientWidth / 1920) * 480 + 'px'
    },
  },
}
</script>

<style scoped lang="scss">
.cooperation {
  margin-top: 40px;
  padding-bottom: 40px;
}
.cooperation-title {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;
}
.cooperation-type {
  width: 200px;
  margin-right: 40px;
  &.right {
    margin-left: 40px;
    margin-right: 0;
  }
  .cooperation-item {
    &.top {
      margin-bottom: 128px;
    }
    .tit {
      font-size: 18px;
      color: #333333;
      margin-bottom: 8px;
    }
    .list-img {
      width: 200px;
    }
  }
}
.cooperation-center {
  flex: 1;
  .img {
    max-width: 100%;
  }
}
</style>
